import ReactDOM from 'react-dom/client'
import { ThemeProvider } from 'styled-components'
import { theme } from '@assets/styles/themes/default.ts'
import GlobalStyles from '@assets/styles/GlobalStyles.ts'
import { RouterProvider, createRouter } from '@tanstack/react-router'
import { routeTree } from './routeTree.gen'
import { Toaster } from 'sonner'

declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router
  }
}

const router = createRouter({
  routeTree,
  defaultPreload: 'intent',
})

const rootElement = document.getElementById('root')!
if (!rootElement.innerHTML) {
  const root = ReactDOM.createRoot(rootElement)
  root.render(
    <ThemeProvider theme={theme}>
      <RouterProvider router={router} />
      <GlobalStyles />
      <Toaster richColors position="top-right" duration={3000} />
    </ThemeProvider>
  )
}