import React, { useCallback, useEffect, useState } from 'react';
import { HomeContainer } from '@pages/Home/styles';
import { Edital, EditaisParticipantes } from '@libs/types';
import { getEditais, getDadosSuporte } from '@api/EditaisService';
import { CardEdital } from '@components/CardEdital';
import { Backdrop } from '@mui/material';
import { Paginator } from '@components/Paginator';
import { TextInput } from '@components/TextInput';
import { Select } from '@components/Select';
import { states } from '@utils/states';
import { editaisEmptyMock } from '../../utils/editaisMock';
import { Header } from '@components/Header';
import { Spin } from 'antd';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { segments } from '@pages/Home/segments';
import useMediaQuery from '@mui/material/useMediaQuery';
import { FlaskIcon } from '@assets/icons/flask';
import { dadosSuporteMock } from '@utils/dadosSuporteMock';
import Slider from '@assets/slider.svg';
import ReCAPTCHA from 'react-google-recaptcha';
import MailchimpPopup from '@components/MailchimpPopup';
import { Banner } from '@components/PreEditalModal/Banner';
import { BackdropRoot, Dialog, DialogContent } from '@mui/material';
import bannerEdital from '@assets/homem-escritorio-banner.png';
import bannerGov from '@assets/mulher-escritorio-banner.png';
import bannerEditalMobile from '@assets/homem-escritorio-banner-mobile.png';
import bannerGovMobile from '@assets/mulher-escritorio-banner-mobile.png';
import logoBBMNET from '@assets/logobbmnet.png'
import logojoinsy from '@assets/logojoinsy.png'

export type PaginationType = {
  take: number;
  skip: number;
};

export type Option = {
  label: string;
  value: string;
};

type SelectOptions = {
  estado: Option[];
  modalidade: Option[];
  orgaoPromotor: Option[];
  EditaisEncerrados: Option[];
  objeto: Option[];
  segmento: Option[];
  cidades: Option[];
};

const PAGINATION: PaginationType = {
  take: 10,
  skip: 0,
};

interface PreEditalModalProps {
  open: boolean;
  onClose: () => void;
  onNavigateToHome: () => void;
}

export const PreEditalModal = ({ open, onClose, onNavigateToHome }: PreEditalModalProps) => {
  const isMobile = useMediaQuery('(max-width:768px)');

  return (
    <>
      <Backdrop
        open={open}
        sx={{
          backdropFilter: 'blur(8px)',
          backgroundColor: 'rgba(255, 255, 255, 0.7)',
          zIndex: 1200,
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0
        }}
      />
      
      <Dialog
        open={open}
        onClose={onClose}
        fullWidth
        maxWidth="lg"
        sx={{
          '& .MuiDialog-container': {
            zIndex: 1300
          }
        }}
        PaperProps={{
          style: {
            borderRadius: '16px',
            backgroundColor: 'transparent',
            boxShadow: 'none',
            overflow: 'hidden',
            margin: isMobile ? '16px' : '32px',
            maxHeight: isMobile ? '90vh' : 'none',
            border: 'none'
          }
        }}
      >
        <DialogContent style={{
          padding: 0,
          overflowX: 'hidden',
          display: 'flex',
          justifyContent: 'center',
          backgroundColor: 'transparent'
        }}>
          <div style={{
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row',
            gap: isMobile ? '20px' : '32px',
            padding: isMobile ? '0' : '16px',
            width: '100%',
            maxWidth: '1200px',
            overflowY: 'auto'
          }}>
            <Banner
              image={isMobile ? bannerEditalMobile : bannerEdital}
              title={`Acesse aqui todos os Editais\npublicados no BBMNET`}
              buttonText="Filtre as oportunidades de seu interesse"
              description="Todas as publicações sem custo e sem cadastro prévio"
              onButtonClick={() => {
                onNavigateToHome();
                onClose();
              }}
              isMobile={isMobile}
              overlayImage={logoBBMNET}
              titleMargin={isMobile ? '16px' : '20px'}
              overlayImageStyle={{
                // Estilos específicos para web (sobrescrevem os padrões)
                ...(!isMobile && {
                  width: '100px', // Tamanho customizado
                  top: '40px',    // Posição vertical
                  left: '40px'    // Posição horizontal
                })
              }}
              style={{
                flex: isMobile ? undefined : 1,
                margin: isMobile ? '0 auto' : undefined,
                maxWidth: isMobile ? '100%' : undefined
              }}
            />

            <Banner
              image={isMobile ? bannerGovMobile : bannerGov}
              title={`Quer aumentar sua\nprodutividade nas vendas\npara o Governo?`}
              buttonText="Conecte-se com compradores públicos e privados e aumente suas vendas"
              description="Maximize suas vendas com as soluções Joinsy"
              isMobile={isMobile}
              isSecondBanner={true}
              contentBackground="#121212"
              customGradient="linear-gradient(to bottom, rgba(18, 18, 18, 0) 0%, rgba(18, 18, 18, 1) 100%)"
              titleColor="#C3D200"
              titleMargin={isMobile ? '0' : '-20px'}
              overlayImage={logojoinsy}
              overlayImageStyle={{
                // Estilos específicos para web (sobrescrevem os padrões)
                ...(!isMobile && {
                  width: '140px', // Tamanho diferente do primeiro banner
                  top: '40px',
                  left: '40px',
                  filter: 'drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.4))' // Sombra mais forte
                })
              }}
              style={{
                flex: isMobile ? undefined : 1,
                margin: isMobile ? '0 auto' : undefined,
                maxWidth: isMobile ? '100%' : undefined
              }}
            />
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};


export const Home = ({ edital }) => {
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [participantes, setParticipantes] = useState<EditaisParticipantes | null>(null);
  const [allParticipantes, setAllParticipantes] = useState<EditaisParticipantes | null>(null);
  const [editalCurrent, setEditalCurrent] = useState<Edital | null>(edital);
  const [loadingCities, setLoadingCities] = useState(false);
  const [cidadesStates, setCidadesStates] = useState<{ label: any; value: any }[]>([]);
  const [selectOptions, setSelectOptions] = useState<SelectOptions>({
    estado: states,
    cidades: cidadesStates,
  } as SelectOptions);
  const [search, setSearch] = useState({
    numeroEdital: '',
    estado: '',
    modalidade: '',
    orgao: '',
    editaisEncerrados: '',
    palavraChave: '',
    objetoId: '',
    objeto: '',
    cidade: '',
    segmento: '',
  });
  const [orgaoSuggestions, setOrgaoSuggestions] = useState<string[]>([]);
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [isDivTwoVisible, setIsDivTwoVisible] = useState(false);
  const isSmallScreen = useMediaQuery('(max-width: 768px)');
  const [filterText, setFilterText] = useState('');
  const [selectedOrgao, setSelectedOrgao] = useState<any>();
  const [showMailchimpPopup, setShowMailchimpPopup] = useState(false);
  const [showCaptcha, setShowCaptcha] = useState(false);
  const [token, setToken] = useState<string | null>(null);
  const [showPreEditalModal, setShowPreEditalModal] = useState(false);

  const SITE_KEY = '6Le38bQpAAAAACwrxYFZlc6AN8Di_mKHCpgMi91f';

  const sortedOrgaoSuggestions = orgaoSuggestions
    .map((item) => ({ label: item, firstLetter: item[0].toUpperCase() }))
    .sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter));

  const toggleDivTwo = () => {
    setIsDivTwoVisible(!isDivTwoVisible);
  };

  const handleSelectOption = (value: string | number, name: string) => {
    setSearch({
      ...search,
      [name]: value,
    });
  };

  const handleGetSupport = () => {
    const { modalidade, ramoAtividade } = dadosSuporteMock;

    type Option = {
      label: string;
      value: string | number;
    };

    const modalidadeOptions = [
      {
        label: 'Concorrência',
        value: 1,
      },
      {
        label: 'Pregão (Setor público)',
        value: 3,
      },
      {
        label: 'Dispensa',
        value: 5,
      },
      {
        label: 'Credenciamento',
        value: 6,
      },
      {
        label: 'Tecnica e Preço',
        value: 7,
      },
    ] as Option[];

    setSelectOptions((prevState) => ({
      ...prevState,
      modalidade: modalidadeOptions,
    }));

    const situacaoOptions = [
      {
        label: 'Não iniciado',
        value: '1',
      },
      {
        label: 'Em execução',
        value: '2',
      },
      {
        label: 'Encerrado',
        value: '3',
      },
    ] as Option[];

    setSelectOptions((prevState) => ({
      ...prevState,
      EditaisEncerrados: situacaoOptions,
    }));

    if (ramoAtividade) {
      const objetoOptions = ramoAtividade.map((objeto: any) => ({
        label: objeto.descricao,
        value: objeto.uniqueId,
      }));

      setSelectOptions((prevState) => ({
        ...prevState,
        objeto: objetoOptions,
      }));
    }
  };

  const handleClear = () => {
    const clearButton = document.querySelector<HTMLElement>('.MuiAutocomplete-clearIndicator');
    if (clearButton) {
      clearButton.click();
    }
    setSearch({
      numeroEdital: '',
      estado: '',
      modalidade: '',
      orgao: '',
      editaisEncerrados: '',
      palavraChave: '',
      objetoId: '',
      objeto: '',
      cidade: '',
      segmento: '',
    });
    setSelectedOrgao(null);
    setCurrentPage(0);
  };

  const fetchCitiesByState = async (estado: string) => {
    if (!estado) return [];

    try {
      const response = await fetch(
        `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${estado}/municipios`
      );
      const result = await response.json();

      if (!Array.isArray(result)) {
        console.error('Resposta inválida da API');
        return [];
      }

      const cities = result
        .map(city => ({
          label: city.nome,
          value: city.nome
        }))
        .sort((a, b) => a.label.localeCompare(b.label));

      return cities;

    } catch (error) {
      console.error('Erro ao buscar cidades:', error);
      return [];
    }
  };

  const handleFilter = useCallback(
    (event?: React.FormEvent<HTMLFormElement>) => {
      event && event.preventDefault();
      let queryString = `?skip=0&take=99999999`;

      if (search.modalidade) {
        queryString += `&modalidade=${encodeURIComponent(search.modalidade)}`;
      }
      if (search.numeroEdital && search.numeroEdital.trim() !== '') {
        queryString += `&numeroEdital=${encodeURIComponent(search.numeroEdital)}`;
      }
      if (search.objeto && search.objeto.trim() !== '') {
        queryString += `&objetoId=${encodeURIComponent(search.objeto)}`;
      }
      if (selectedOrgao && selectedOrgao.label.trim() !== '') {
        queryString += `&orgao=${encodeURIComponent(selectedOrgao.label)}`;
      }
      if (search.estado && search.estado.trim() !== '') {
        queryString += `&estado=${encodeURIComponent(search.estado)}`;
      }
      if (search.editaisEncerrados && search.editaisEncerrados.trim() !== '') {
        queryString += `&situacao=${encodeURIComponent(search.editaisEncerrados)}`;
      }
      if (search.palavraChave && search.palavraChave.trim() !== '') {
        queryString += `&palavraChave=${encodeURIComponent(search.palavraChave)}`;
      }
      if (search.cidade && search.cidade.trim() !== '') {
        queryString += `&cidade=${encodeURIComponent(search.cidade)}`;
      }
      if (search.segmento && search.segmento.trim() !== '') {
        queryString += `&segmento=${encodeURIComponent(search.segmento)}`;
      }

      const fullUrl = `https://jornal.prod.back.mageserver.com.br/edital${queryString}`;
      console.log('URL completa da requisição:', fullUrl);

      setLoading(true);

      fetch(fullUrl)
        .then((response) => response.json())
        .then((result) => {
          const sortedEditais = result.content.sort((a, b) => {
            const dateA = a.publishedAt ? new Date(a.publishedAt).getTime() : 0;
            const dateB = b.publishedAt ? new Date(b.publishedAt).getTime() : 0;
            return dateB - dateA;
          });

          setAllParticipantes({
            editais: sortedEditais,
            count: Math.ceil((sortedEditais.length ?? 1) / PAGINATION.take),
          });
          setParticipantes({
            editais: sortedEditais.slice(0, PAGINATION.take),
            count: Math.ceil((sortedEditais.length ?? 1) / PAGINATION.take),
          });
        })
        .catch((error) => {
          console.error('Erro na requisição:', error);
          setAllParticipantes(editaisEmptyMock);
          setParticipantes(editaisEmptyMock);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [search, selectedOrgao],
  );

  const handleSignupClick = () => {
    setShowCaptcha(true);
    setShowMailchimpPopup(false);
  };

  const handleCaptchaChange = (token: string | null) => {
    if (token) {
      setToken(token);
      setShowCaptcha(false);
      setShowMailchimpPopup(true);
    } else {
      setShowCaptcha(false);
      setShowMailchimpPopup(false);
    }
  };

  const handleMailchimpClose = () => {
    setShowMailchimpPopup(false);
    setToken(null);
  };

  const handleOrgaoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event) return;
    const nomeOrgao = event.currentTarget.value;
    const queryString = `?skip=0&take=99999999`;
    setSearch({ ...search, orgao: nomeOrgao });
    setSelectedOrgao({ label: nomeOrgao, value: nomeOrgao });
    fetch(`https://jornal.prod.back.mageserver.com.br/edital?${queryString}`)
      .then((response) => response.json())
      .then((result) => {
        const orgaoNames = result.content
          .map((edital: any) => edital.nomeDoOrgaoPromotor)
          .filter((item: any, pos: any, self: string | any[]) => {
            return (
              self.indexOf(item) === pos &&
              item.toLowerCase().includes(nomeOrgao.toLowerCase())
            );
          });
        setOrgaoSuggestions(orgaoNames);
      });
  };

  useEffect(() => {
    handleGetSupport();
    handleFilter();

    fetch(`https://jornal.prod.back.mageserver.com.br/edital?skip=0&take=999999999`)
      .then((response) => response.json())
      .then((result) => {
        const sortedEditais = result.content.sort((a, b) => {
          const dateA = a.publishedAt ? new Date(a.publishedAt).getTime() : 0;
          const dateB = b.publishedAt ? new Date(b.publishedAt).getTime() : 0;
          return dateB - dateA;
        });

        const orgaoNames = sortedEditais
          .map((edital) => edital.nomeDoOrgaoPromotor)
          .filter((item, pos, self) => self.indexOf(item) === pos);

        setOrgaoSuggestions(orgaoNames);
        setAllParticipantes({
          editais: sortedEditais,
          count: Math.ceil((sortedEditais.length ?? 1) / PAGINATION.take),
        });
        setParticipantes({
          editais: sortedEditais.slice(0, PAGINATION.take),
          count: Math.ceil((sortedEditais.length ?? 1) / PAGINATION.take),
        });
      })
      .catch(error => {
        console.error('Erro ao buscar editais:', error);
      });

    // Mostrar modal automaticamente após 2 segundos (opcional)
    const timer = setTimeout(() => {
      setShowPreEditalModal(true);
    }, 0.500);

    return () => clearTimeout(timer);
  }, []);

  const handlePageChange = (page: PaginationType) => {
    if (
      page.skip === Math.ceil((participantes?.count ?? 1) - 1 / PAGINATION.take)
    )
      return;

    setCurrentPage(page.skip);
    if (allParticipantes) {
      const startIndex = page.skip * page.take;
      const endIndex = startIndex + page.take;
      setParticipantes({
        editais: allParticipantes.editais.slice(startIndex, endIndex),
        count: allParticipantes.count,
      });
    }
  };

  const handleFilterTextChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setFilterText(event.target.value);
  };

  const borderRadiusStyle = {
    borderRadius: isDivTwoVisible
      ? '0px'
      : window.innerWidth < 900
        ? '10px'
        : '0px 0px 10px 10px',
    height: window.innerWidth < 900 ? '120%' : 'auto',
    padding: window.innerWidth < 900 ? '1.2em' : 'auto',
  };

  const handleFilterButtonClick = () => {
    setSearch({
      ...search,
      palavraChave: filterText,
    });
    handleFilter();
  };

  const scrollToFilters = () => {
    const filtersSection = document.getElementById('filters-section');
    if (filtersSection) {
      filtersSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <HomeContainer>

<Backdrop
        open={showPreEditalModal}
        sx={{
          backdropFilter: 'blur(8px)',
          backgroundColor: 'rgba(255, 255, 255, 0.7)',
          zIndex: 1200, // Valor alto para ficar sobre outros elementos
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0
        }}
      />

      <Header />
      <section>
        <header>
          <div className="titles">
            <h4>Jornal do Licitante</h4>
            <h6>Editais Publicados</h6>
          </div>
          {!isSmallScreen && (
            <div className="newsletter">
              <span>Quer receber os Editais por e-mail?</span>
              {showCaptcha ? (
                <div className="captcha-container">
                  <ReCAPTCHA
                    key={Math.random()}
                    sitekey={SITE_KEY}
                    onChange={handleCaptchaChange}
                  />
                </div>
              ) : (
                <button
                  className="register"
                  style={{ width: '100%' }}
                  onClick={handleSignupClick}
                >
                  Cadastre aqui
                </button>
              )}
              {showMailchimpPopup && <MailchimpPopup onClose={handleMailchimpClose} />}
            </div>
          )}
        </header>
      </section>
      <main>
        <form
          style={{
            display: 'contents',
            width: 'inherit',
            backgroundColor: '#f8f4f4',
          }}
          onSubmit={handleFilter}
          id="filters-section"
        >
          {window.innerWidth > 900 && (
            <div
              className="divextra"
              style={{
                backgroundColor: '#004aa0',
                width: '100%',
                paddingTop: '10px',
                borderRadius: '12px 12px 0 0',
              }}
            >
              {' '}
              <span
                style={{
                  color: 'white',
                  marginLeft: '2.5em',
                  fontSize: '26px',
                  fontWeight: 'bold',
                }}
              >
                Filtros
              </span>
            </div>
          )}
          <div className="divone" style={borderRadiusStyle}>
            <TextInput
              let
              onChange={(event) =>
                handleSelectOption(event.currentTarget.value, 'palavraChave')
              }
              value={search.palavraChave}
              label={
                window.innerWidth > 900 ? (
                  <span
                    style={{
                      color: 'white',
                      paddingLeft: '5em',
                      paddingBottom: '10px',
                    }}
                  >
                    Buscar
                  </span>
                ) : (
                  <span></span>
                )
              }
              placeholder="Palavra Chave"
              style={{
                width: window.innerWidth < 900 ? '100% !important' : '40em',
                alignSelf: 'center',
                height: window.innerWidth < 900 ? '2.5em' : '2.5em',
                borderRadius: '22px',
                borderColor: 'white',
                marginRight: '10px',
                marginLeft: '5em',
                paddingLeft: '2em',
                marginBottom: '26px',
                marginTop: '10px',
                fontSize: window.innerWidth < 900 ? '20px' : '18px',
                color: 'black',
                boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
              }}
              className="palavra-chave"
            />
            <div className="blue">
              {window.innerWidth > 900 && (
                <button
                  className="filter-button"
                  type="submit"
                  style={{
                    fontSize: '20px',
                    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                    borderRadius: '30px',
                    width: '6em',
                    height: '2em',
                    margin: '0',
                  }}
                >
                  Filtrar
                </button>
              )}
              {window.innerWidth > 900 && (
                <button
                  className="filter-button"
                  style={{
                    fontSize: '20px',
                    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                    borderRadius: '30px',
                    width: '6em',
                    height: '2em',
                    margin:
                      window.innerWidth < 900 ? '0.5em' : '0 0.5em 0 0.5em',
                  }}
                  onClick={handleClear}
                >
                  Limpar
                </button>
              )}
            </div>

            {window.innerWidth <= 900 && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  margin: '1em',
                }}
              >
                <button
                  className="filter-button"
                  type="submit"
                  style={{
                    fontSize: '20px',
                    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                    borderRadius: '30px',
                    width: '6em',
                    height: '2em',
                    margin: '0',
                    alignSelf: 'center',
                    justifySelf: 'center',
                  }}
                >
                  Filtrar
                </button>
                <button
                  className="filter-button"
                  style={{
                    fontSize: '20px',
                    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                    borderRadius: '30px',
                    width: '6em',
                    height: '2em',
                    margin:
                      window.innerWidth < 900 ? '0.5em' : '0 0.5em 0 0.5em',
                  }}
                  onClick={handleClear}
                >
                  Limpar
                </button>

                <div>
                  <img
                    className="slider"
                    src={Slider}
                    alt=""
                    width={32}
                    style={{ filter: 'invert(100%)' }}
                  />
                  <button
                    className="mais-filtros-button"
                    onClick={toggleDivTwo}
                    style={{
                      marginLeft: window.innerWidth < 900 ? '10px' : '4em',
                      padding:
                        window.innerWidth < 900
                          ? '0px 0px 0px 10px'
                          : '5px 10px',
                      fontSize: '22px',
                    }}
                  >
                    Mais filtros
                  </button>
                </div>
              </div>
            )}

            {window.innerWidth > 900 && (
              <div className="mais-filtros-div">
                <img
                  className="slider"
                  src={Slider}
                  alt=""
                  width={32}
                  style={{ filter: 'invert(100%)' }}
                />
                <button
                  className="mais-filtros-button"
                  onClick={toggleDivTwo}
                  style={{
                    marginLeft: window.innerWidth < 900 ? '0em' : '4em',
                    padding: window.innerWidth < 900 ? '0px' : '5px 10px',
                    fontSize: '22px',
                  }}
                >
                  Mais filtros
                </button>
              </div>
            )}
          </div>

          {isDivTwoVisible && (
            <div
              className="divtwo"
              style={{
                alignItems: window.innerWidth < 900 ? 'start' : 'auto',
              }}
            >
              <Autocomplete
                options={sortedOrgaoSuggestions}
                getOptionLabel={(option) => option.label}
                onInputChange={handleOrgaoChange}
                value={selectedOrgao}
                noOptionsText={
                  <div style={{
                    fontSize: '16px',
                    fontFamily: 'Montserrat',
                    fontWeight: '400'
                  }}>
                    Nenhum Resultado
                  </div>
                }
                onChange={(event, newValue) => setSelectedOrgao(newValue)}
                renderOption={(props, option) => (
                  <li {...props} style={{
                    fontSize: '16px',
                    padding: '12px 16px',
                    fontFamily: 'Montserrat',
                    fontWeight: '400'
                  }}>
                    {option.label}
                  </li>
                )}
                ListboxProps={{
                  style: {
                    fontSize: '16px',
                    maxHeight: '400px',
                    backgroundColor: 'white'
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className="orgao-input"
                    placeholder="Orgão"
                    variant="outlined"
                    sx={{
                      '& .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiAutocomplete-inputRoot': {
                        padding: '0',
                      },
                      '& .MuiInputBase-input': {
                        color: 'black',
                        fontFamily: 'Montserrat',
                        fontSize: '2.2em',
                        fontWeight: '500',
                        marginRight: '0em',
                        backgroundColor: 'white',
                        padding: '0',
                        borderRadius: '21px',
                        textAlign: 'center',
                        '@media (max-width: 900px)': {
                          width: '230px !important',
                          height: '31px !important',
                        },
                      },
                      '& .MuiInputBase-root': {
                        padding: '0',
                        backgroundColor: 'white',
                        borderRadius: '21px',
                        width: '230px',
                        '@media (max-width: 900px)': {
                          width: '70% !important',
                          height: '31px !important',
                        },
                      },
                      '& .MuiAutocomplete-hasPopupIcon': {
                        padding: '0',
                      },
                      '& .MuiAutocomplete-input': {
                        padding: '0',
                        '@media (max-width: 900px)': {
                          width: '70% !important',
                          height: '31px !important',
                        },
                      },
                      '& .MuiSvgIcon-root': {
                        color: 'transparent',
                      },
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          padding: '0',
                          width: '100%',
                          color: 'transparent',
                          borderColor: 'transparent',
                        },
                      },
                      '& .MuiInputLabel-root': {
                        color: 'black',
                        fontSize: '3rem',
                        fontFamily: 'Montserrat',
                      },
                      '& .MuiButtonBase-root': {
                        marginRight: '1.5em',
                      },
                    }}
                  />
                )}
              />
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignContent: 'center',
                }}
              >
                {' '}
                <TextField
                  className="numero-input"
                  onChange={(event) =>
                    handleSelectOption(
                      event.currentTarget.value,
                      'numeroEdital'
                    )
                  }
                  value={search.numeroEdital}
                  placeholder="No. Edital"
                  variant="outlined"
                  sx={{
                    '& .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl': {
                      padding: '0',
                    },
                    '& .MuiInputBase-input': {
                      color: 'black',
                      fontFamily: 'Montserrat',
                      fontSize: '2.2em',
                      fontWeight: '500',
                      marginRight: '0em',
                      backgroundColor: 'white',
                      padding: '0',
                      borderRadius: '21px',
                      textAlign: 'center',
                      '@media (max-width: 900px)': {
                        width: '230px !important',
                        height: '31px !important',
                      },
                    },
                    '& .MuiInputBase-root': {
                      padding: '0',
                      backgroundColor: 'white',
                      borderRadius: '21px',
                      width: '230px',
                      '@media (max-width: 900px)': {
                        width: '70% !important',
                        height: '31px !important',
                      },
                    },
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        padding: '0',
                        width: '100%',
                        color: 'transparent',
                        borderColor: 'transparent',
                      },
                    },
                    '& .MuiInputLabel-root': {
                      color: 'black',
                      fontSize: '3rem',
                      fontFamily: 'Montserrat',
                    },
                  }}
                />
              </div>
              <div className="selector">
                <Select
                  label=""
                  onChange={(value) => handleSelectOption(+value, 'modalidade')}
                  value={search.modalidade}
                  options={selectOptions?.modalidade ?? []}
                  placeholder="Modalidade"
                  style={{ width: '230px' }}
                />
              </div>
              <div className="selector">
                <Select
                  label=""
                  onChange={(value) =>
                    handleSelectOption(value, 'editaisEncerrados')
                  }
                  value={search.editaisEncerrados}
                  options={selectOptions?.EditaisEncerrados ?? []}
                  placeholder="Situação"
                  style={{ width: '230px' }}
                />
              </div>
              <div className="selector">
                <Select
                  label=""
                  onChange={(value) => handleSelectOption(value, 'estado')}
                  value={search.estado}
                  options={selectOptions?.estado ?? []}
                  placeholder="Estado"
                  style={{ width: '230px' }}
                />
              </div>
              <div className="selector">
                <Select
                  label=""
                  onChange={(value) => handleSelectOption(value, 'cidade')}
                  value={search.cidade}
                  options={selectOptions.cidades || []}
                  placeholder={loadingCities ? "Carregando..." : "Cidade"}
                  disabled={loadingCities || !search.estado}
                  style={{ width: '230px' }}
                />
              </div>
              <div className="selector">
                <Select
                  label=""
                  onChange={(value) => handleSelectOption(value, 'objeto')}
                  value={search.objeto}
                  options={selectOptions?.objeto ?? []}
                  placeholder="Objeto"
                  style={{ width: '230px' }}
                />
              </div>
              <div className="selector">
                <Select
                  label=""
                  onChange={(value) => handleSelectOption(value, 'segmento')}
                  value={search.segmento}
                  options={segments}
                  placeholder="Segmento"
                  style={{ width: '230px' }}
                />
              </div>
            </div>
          )}
        </form>
        <div
          className="caderno"
          style={{
            width: '100%',
            height: '30%',
            marginTop: '1em',
            marginBottom: '1em',
            justifyContent: 'left',
            marginLeft: '1em',
            backgroundColor: '#f8f4f4',
            boxSizing: 'border-box',
          }}
        >
          <span
            style={{
              color: '#084ca4',
              fontWeight: 'bold',
              fontFamily: 'Montserrat',
              fontSize: '2em',
              marginLeft: '2em',
            }}
          >
            {search.palavraChave && `${search.palavraChave} `}
          </span>
        </div>
        <div className="divthree">
          {loading ? (
            <Spin size="large" />
          ) : (
            <div className="card-list">
              {participantes?.editais.length > 0 ? (
                participantes?.editais.map((edital) => (
                  <CardEdital key={edital.id} edital={edital} />
                ))
              ) : (
                <div className="no-results">
                  <span
                    style={{
                      color: '#084ca4',
                      fontWeight: 'bold',
                      fontFamily: 'Montserrat',
                      fontSize: '2em',
                      marginLeft: '4em',
                      paddingBottom: '2em',
                    }}
                  >
                    Nenhum resultado encontrado
                  </span>
                </div>
              )}
            </div>
          )}
        </div>
      </main>
      <footer>
        <Paginator
          onPageChange={handlePageChange}
          currentPage={currentPage}
          totalPages={participantes?.count ?? 1}
          take={PAGINATION.take}
        />
      </footer>

      {/* Modal de Pré-Edital */}
      <PreEditalModal
        open={showPreEditalModal}
        onClose={() => setShowPreEditalModal(false)}
        onNavigateToHome={() => {
          scrollToFilters();
          setShowPreEditalModal(false);
        }}
      />
    </HomeContainer>
  );
};


