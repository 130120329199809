import { CSSProperties } from 'react';

export const bannerStyles = (isMobile?: boolean, isSecondBanner?: boolean) => ({
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    width: isMobile ? '90vw' : '600px',
    height: 'auto',
    minHeight: isMobile ? '420px' : 'auto',
    borderRadius: '16px',
    overflow: 'hidden',
    marginBottom: isMobile ? '16px' : '24px',
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)',
    ...(isMobile && {
      maxWidth: '100%',
      marginLeft: 'auto',
      marginRight: 'auto'
    })
  } as CSSProperties,

  imageContainer: {
    position: 'relative',
    width: '100%',
    height: isMobile ? '200px' : '350px',
    overflow: 'hidden'
  } as CSSProperties,

  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    objectPosition: 'center',
    display: 'block',
    aspectRatio: isMobile ? '16/9' : undefined
  } as CSSProperties,

  gradient: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    height: '40%',
    background: 'linear-gradient(to bottom, rgba(0, 74, 160, 0) 0%, rgba(0, 74, 160, 1) 100%)'
  } as CSSProperties,

  content: {
    width: '100%',
    background: '#004AA0',
    padding: isMobile ? '20px 16px' : '24px',
    display: 'flex',
    flexDirection: 'column',
    gap: isMobile ? '14px' : '16px',
    flex: 1
  } as CSSProperties,

  textContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: isMobile ? '10px' : '12px'
  } as CSSProperties,

  title: {
    fontSize: isMobile ? '22px' : '28px',
    margin: '0',
    fontWeight: 500,
    whiteSpace: 'pre-line',
    color: '#FFFFFF',
    lineHeight: '1.3',
    ...(isMobile && {
      display: '-webkit-box',
      WebkitLineClamp: 3,
      WebkitBoxOrient: 'vertical',
      overflow: 'hidden'
    })
  } as CSSProperties,

  description: {
    fontSize: isMobile ? '15px' : '16px',
    color: 'rgba(255, 255, 255, 0.9)',
    margin: '0',
    lineHeight: '1.4',
    ...(isMobile && {
      display: '-webkit-box',
      WebkitLineClamp: 2,
      WebkitBoxOrient: 'vertical',
      overflow: 'hidden'
    })
  } as CSSProperties,

  button: {
    padding: isMobile ? '12px 16px' : '12px 24px',
    fontSize: isMobile ? '15px' : '16px',
    backgroundColor: 'transparent',
    color: '#FFFFFF',
    border: '1px solid #FFFFFF',
    borderRadius: '8px',
    cursor: 'pointer',
    marginTop: isMobile ? '10px' : '12px',
    transition: 'all 0.3s ease',
    minHeight: '48px',
    /* Ajuste específico para web - igual ao segundo banner */
    ...(!isMobile && {
      alignSelf: 'center',
      width: '100%',
      textAlign: 'center',
      whiteSpace: 'normal',
      wordBreak: 'break-word',
      ':hover': {
        backgroundColor: 'rgba(255, 255, 255, 0.1)'
      }
    }),
    /* Mantém o comportamento mobile existente */
    ...(isMobile && isSecondBanner && {
      alignSelf: 'center',
      width: '100%',
      textAlign: 'center',
      whiteSpace: 'normal',
      wordBreak: 'break-word'
    })
  } as CSSProperties,

  /* ESTILO DA LOGO PERMANECE EXATAMENTE IGUAL */
  overlay: {
    position: 'absolute',
    top: isMobile ? '16px' : '24px',    // Mantido seu valor original
    left: isMobile ? '16px' : '24px',   // Mantido seu valor original
    zIndex: 2,
    width: isMobile ? '80px' : '120px', // Mantido seu valor original
    height: 'auto',
    filter: 'drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.3))',
    ...(isMobile && {
      top: '12px',
      left: '12px'
    })
  } as CSSProperties
});