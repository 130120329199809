import React from 'react';
import { CSSProperties } from 'react';
import { bannerStyles } from '@components/PreEditalModal/styles';

interface BannerProps {
  image: string;
  title: string;
  description: string;
  buttonText: string;
  titleMargin?: string;
  onButtonClick?: () => void;
  buttonDisabled?: boolean;
  customGradient?: string;
  contentBackground?: string;
  titleColor?: string;
  overlayImage?: string;
  overlayImageStyle?: CSSProperties;
  style?: CSSProperties;
  isMobile?: boolean;
  isSecondBanner?: boolean;
}

export const Banner = ({
  image,
  title,
  description,
  buttonText,
  titleMargin,
  onButtonClick,
  buttonDisabled = false,
  customGradient,
  contentBackground,
  titleColor = "#FFFFFF",
  overlayImage,
  overlayImageStyle,
  style,
  isMobile = false,
  isSecondBanner = false
}: BannerProps) => {
  const styles = bannerStyles(isMobile, isSecondBanner);

  const handleButtonInteraction = (e: React.MouseEvent<HTMLButtonElement>, hover: boolean) => {
    if (!buttonDisabled) {
      e.currentTarget.style.backgroundColor = hover ? "rgba(255, 255, 255, 0.1)" : "transparent";
    }
  };

  return (
    <article style={{ ...styles.container, ...style }}>
      <div style={styles.imageContainer}>
        <img
          src={image}
          alt=""
          role="presentation"
          style={styles.image}
        />
        
        <div style={{ 
          ...styles.gradient,
          background: customGradient || styles.gradient.background 
        }} />
        
        {overlayImage && (
          <div style={styles.overlay}>
            <img 
              src={overlayImage} 
              alt="Logo" 
              style={{ width: '100%', height: 'auto' }} 
            />
          </div>
        )}
      </div>

      <div style={{ 
        ...styles.content,
        background: contentBackground || styles.content.background 
      }}>
        <div style={styles.textContent}>
          <h3 style={{ 
            ...styles.title,
            color: titleColor,
            marginTop: titleMargin || styles.title.marginTop
          }}>
            {title}
          </h3>
        </div>

        <button
          style={styles.button}
          aria-label={buttonText}
          onMouseEnter={(e) => handleButtonInteraction(e, true)}
          onMouseLeave={(e) => handleButtonInteraction(e, false)}
          onClick={() => !buttonDisabled && onButtonClick?.()}
          disabled={buttonDisabled}
        >
          {buttonText}
        </button>

        <p style={styles.description}>
          {description}
        </p>
      </div>
    </article>
  );
};